
import AsyncStorage from '@callstack/async-storage';

async function removeToken() {
    await AsyncStorage.removeItem('token');
}

async function setToken(token) {
    await AsyncStorage.setItem('token', token);
}

async function getToken() {

    await AsyncStorage.getItem('token');
}

async function removeParceiroNegocio() {
    await AsyncStorage.removeItem('parceiroNegocio');
}

async function setParceiroNegocio(token) {
    await AsyncStorage.setItem('parceiroNegocio', token);
}

async function getParceiroNegocio() {

    return AsyncStorage.getItem('parceiroNegocio');
}

export default {
    getToken,
    setToken,
    removeToken,
    removeParceiroNegocio,
    setParceiroNegocio,
    getParceiroNegocio
};
